<template>
  <Header v-if="false" />
  <router-view v-if="desktop" />
  <div class="mobileMessage" v-if="!desktop">
    <div class="leaf">
      <p>The "Jungle" does not fit in the mobile environment at the moment. Please use a desktop browser for the full experience.</p>
    </div>
  </div>
  <Help v-if="desktop" />
  <Infos v-if="desktop" />
  <Audio />
  <div v-if="showDeadMansSwitch" id="resetNotification">
    <p>Are you still there?</p>
      <p>In 10 Seconds this website will be reloaded</p>
      <p class="button" @click="interruptReload()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.72 26.28">
          <polygon fill="#231f20" points="45.72 9.97 22.79 9.97 22.79 0 0 13.14 22.79 26.28 22.79 16.31 45.72 16.31 45.72 9.97"/>
        </svg>
        No, I'm still there!</p>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Infos from '@/components/InfosOverlay.vue'
import Help from './components/Help.vue'
import Audio from './components/Audio.vue'

export default {
  name: 'App',
  components: {
    Header,
    Infos,
    Help,
    Audio
  },
  data () {
    return {
      desktop: false,
      watchDog: undefined,
      reload: undefined,
      timeout: 120,
      showDeadMansSwitch: false
    }
  },
  methods: {
    adaptFontSize () {
      document.querySelector('body').style.fontSize = `${window.devicePixelRatio * 100}%`
      console.log(document.querySelector('body').style.fontSize)
    },
    checkOrientation () {
      // const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
      // console.log(orientation)
      // if (orientation !== undefined) {
      // this.desktop = orientation === 'landscape-primary'
      // } else {
      this.desktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      // }
    },
    resetLocalStorage () {
      localStorage.clear()
    },
    hideStoryOverlay () {
      ['left', 'right'].forEach(side => {
        this.$store.dispatch('setStoryOverlay', {
          side: side,
          content: {
            visibility: false
          }
        })
      })
    },
    stayAwake () {
      clearTimeout(this.watchDog)
      this.watchDog = setTimeout(() => {
        console.log('watchdog triggered')
        this.showDeadMansSwitch = true
        this.reload = setTimeout(() => {
          console.log('reload triggered')
          this.showDeadMansSwitch = false
          this.resetLocalStorage()
          this.hideStoryOverlay()
          // if (this.$route.path !== '/') {
          //   this.$router.push('/')
          // }
          window.location.replace(location.protocol + '//' + window.location.host)
        }, 20000)
      }, this.timeout * 1000)
    },
    interruptReload () {
      clearInterval(this.reload)
      this.stayAwake()
      this.showDeadMansSwitch = false
    }
  },
  created () {
    this.checkOrientation()
    window.addEventListener('mousemove', this.stayAwake)
    window.addEventListener('keypress', this.stayAwake)
    console.log(this.$refs)
  },
  unmounted () {
    window.removeEventListener('mousemove', this.stayAwake)
    window.removeEventListener('keypress', this.stayAwake)
  },
  watch: {
    $route: {
      handler (to, from) {
        document.title = to.meta.title || 'The Jungle of Eden'
      },
      immediate: true
    }
  }
}
</script>

<style>

  @font-face {
    font-family: 'Switzer';
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local('Switzer'), url('/fonts/Switzer-Medium.woff2') format('woff2'), url('/fonts/Switzer-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Switzer';
    font-weight: 500;
    font-style: italic;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local('Switzer'), url('/fonts/Switzer-MediumItalic.woff2') format('woff2'), url('/fonts/Switzer-MediumItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Switzer';
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local('Switzer'), url('/fonts/Switzer-Bold.woff2') format('woff2'), url('/fonts/Switzer-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Switzer';
    font-weight: 900;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local('Switzer'), url('/fonts/Switzer-Black.woff2') format('woff2'), url('/fonts/Switzer-Black.woff') format('woff');
  }

  @font-face {
    font-family: 'Courier Prime';
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local('Courier Prime'), url('/fonts/CourierPrime-Regular.woff2') format('woff2'), url('/fonts/CourierPrime-Regular.woff') format('woff');
  }

  body {
    margin: 0;
    color: #000;
    overflow: hidden;
    font-size: 100%;
    user-select: none;
  }

  #app {
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100vw;
    height: 100vh;
  }

  .mobileMessage {
    width: 100vw;
    height: 100vh;
    background-color: #004E63;
    background-image: -webkit-image-set(url('~@/assets/mobile-bg-1x.png') 1.25x, url('~@/assets/mobile-bg-2x.png') 2x);
    background-size: cover;
    background-position: center;
  }

  .leaf {
    width: 100vw;
    height: 100vh;
    background-image: -webkit-image-set(url('~@/assets/mobile-leaf-1x.png') 1.25x, url('~@/assets/mobile-leaf-2x.png') 2x);
    background-size: cover;
    background-position: center;
  }

  .mobileMessage p {
    position: absolute;
    width: 70vw;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: 'Switzer';
    font-size: 5vh;
    font-weight: 700;
  }

  #resetNotification {
    background-color: rgb(255 255 255 / 100%);
    max-width: 900px;
    padding: 1.5vh 3vw;
    border: 0.4vh solid #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    font-size: 3vh;
    text-align: left;
  }

  #resetNotification p svg {
    display: inline-block;
    height: 0.5em;
    margin: 0vh 0.25vw 0vh 0vw;
    padding-bottom: 0.25vh;
  }

  h2 {
    font-size: 3.5rem;
  }

  .hidden {
    display: none;
  }

  .flatShadow:not(svg) {
    box-shadow:
    1px 1px 0px 0px #000,
    2px 2px 0px 0px #000,
    3px 3px 0px 0px #000,
    4px 4px 0px 0px #000,
    5px 5px 0px 0px #000,
    6px 6px 0px 0px #000;
  }

  .flatShadow {
    filter:
    drop-shadow(1px 1px 0px #000)
    drop-shadow(2px 2px 0px #000)
    drop-shadow(3px 3px 0px #000);
  }

  .navigationArrow {
    stroke: #000;
  }

  .button:hover {
    cursor: pointer;
  }

</style>
