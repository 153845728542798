<template>
  <div class="arrivalBoard">
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  name: 'ArrivalBoard',
  props: {
    msg: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.arrivalBoard {
  background-color: #000;
  height: min-content;
  overflow: hidden;
  padding: 0.5vh 0vh 1.5vh;
  /* display: flex;
  flex-wrap: nowrap; */
  align-items: baseline;
  width: 100%;
}

.arrivalBoard > * {
  animation: marquee 25s linear infinite;
}

.arrivalBoard p {
  line-height: 1;
  margin: 0;
  margin-bottom: 0.1em;
  padding: 0;
  white-space: nowrap;
  min-width: 100%;
  width: min-content;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

</style>
