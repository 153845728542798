<template>
  <p v-if="!entered" class="ageDisclaimer">This project contains 18+ content indicated by this symbol: <span class="redDot"></span></p>
  <button v-if="!entered" @click="entered = true" id="start" class="button flatShadow">
    Enter
  </button>

  <div v-if="entered" id="questions">
    <p>Before entering, please answer a few questions:</p>
    <ol>
      <li v-for="item, index in filteredQuestions" :key="index">{{ item.question }} <span @click="item.answer = true" :class="{selected: item.answer}">Yes</span>/<span @click="item.answer = false" :class="{selected: item.answer == false}">No</span></li>
      <li>Pick {{ tagMaxSelection }} tags that fit you best:</li>
    </ol>

    <div id="tags">
      <p class="button" :class="{selected: selected}" @click="selectTag(tag, selected)" v-for="(selected, tag) in tags" :key="tag">{{ tag }}</p>
      <svg @click="enter()" class="enter button flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.35 113.16">
        <path d="M91.18,5.5a577.59,577.59,0,0,1,110,10c42.77,8,54.76,14.87,62,26,7.73,11.86,11.11,30.17,3,41-8.48,11.35-26.37,10.2-42,10-78.34-1-81.94,22.2-141,13-27.85-4.34-57.76-9-71-33-8.83-16-9.64-39.58,2-54C19.32,12.14,28.62,5.41,91.18,5.5Z"/>
        <text text-anchor="middle" x="135" y="75">Enter</text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserFactsForm',
  data () {
    return {
      questions: undefined,
      tags: {},
      tagMaxSelection: 5,
      entered: false
    }
  },
  async created () {
    this.questions = this.$store.getters.getQuestions

    const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=tags')
    const data = await response.json()
    data.forEach(entry => {
      this.tags[entry.tag] = false
    })

    const questions = JSON.parse(localStorage.getItem('questions'))
    this.questions = Object.assign(this.questions, questions)

    const tags = JSON.parse(localStorage.getItem('tags'))
    this.tags = Object.assign(this.tags, tags)
  },
  methods: {
    selectTag (tag, selected) {
      const selectionCount = Object.values(this.tags)
        .filter(value => value === true)
        .length
      if (selectionCount < this.tagMaxSelection || selected) this.tags[tag] = !selected
    },
    resetQuestions () {
      this.questions.forEach(item => {
        item.answer = null
      })
    },
    async enter () {
      // console.log(this.questions, this.tags)
      localStorage.setItem('questions', JSON.stringify(this.questions))
      localStorage.setItem('tags', JSON.stringify(this.tags))

      const answers = {
        questions: {},
        tags: []
      }
      this.questions.forEach((item, index) => {
        // if (item.answer === undefined) {
        //   if (Array.isArray(item.condition) && this.questions[item.condition[0]].answer === item.condition[1]) {
        //     console.log('hey!')
        //     return false
        //   }
        // }
        answers.questions[`q${index + 1}`] = item.answer ? 1 : 0
      })
      for (const [tag, value] of Object.entries(this.tags)) {
        if (value === false) continue
        answers.tags.push(tag)
      }

      this.$store.dispatch('setSelectedTags', answers.tags)
      // console.log(JSON.stringify(answers))

      const response = await fetch('https://ayla.raphaeldas.ch/user-facts.php', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(answers)
      })
      const data = await response.json()
      // TODO: überschüssige tags löschen
      // TODO: Furniture direkt ohne animation einfügen
      // TODO: try rotate arrow with filter css rule
      // TODO: legend fixed witdh
      // TODO: hover animation slittly bigger
      if (data.userId) {
        localStorage.setItem('userId', JSON.stringify(data.userId))
        this.$router.push('stories')
      }
    }
  },
  computed: {
    filteredQuestions () {
      return this.questions.filter(question => {
        return question.condition === true || this.questions[question.condition[0]].answer === question.condition[1]
      })
    },
    content: {
      get () {
        return this.$store.getters.getQuestions
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#start {
  font-size: 5vw;
  font-weight: 700;
  background-color: #ffb19c;
  display: inline-flex;
  border: 0.2vw solid #000;
  padding: 0.75vh 1.5vw;
  margin: 2vh auto 1vh;
  height: min-content;
}

.ageDisclaimer {
  padding: 0.5vh 1vw;
  background-color: #FFB19C;
  border: 0.4vh solid #004E63;
  height: min-content;
  font-size: 2vh;
  width: calc(100% - 2vw);
  margin: 0;
}

.redDot {
  display: inline-block;
  background-color: #F15A24;
  border-radius: 50%;
  border: 0.4vh solid #000;
  height: 1.4vh;
  width: 1.4vh;
  vertical-align: middle;
  margin-left: 0.5em;
}

#questions {
  background-color: rgb(255 204 192 / 95%);
  border: 0.4vh solid #000;
  padding: 1vh 1vw;
  width: calc(100% - 2vw);
  font-family: 'Courier Prime', Courier, monospace;
  text-align: left;
  font-size: 1.9vh;
}

#questions p {
  margin: 1vh 0vw 1vh 1vw;
}

ol {
  margin-left: 1vw;
  padding-left: 2vw;
}

ol li {
  margin: 1vh 0vw;
}

ol li span {
  font-style: italic;
  cursor: pointer;
  margin: 0vh 0.1vw;
}

ol li span.selected {
  text-decoration: underline solid #FFFF00 0.5vh;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

#tags p {
  margin: 0.5vh 0.3vw;
  background-color: #C4C4C4;
  border: 0.4vh solid #C4C4C4;
  border-radius: 4vh;
  padding: 0.2vh 0.6vw;
  height: min-content;
}

#tags p.selected {
  border-color: #FFFF00;
}

.enter {
  margin: 1vh 1vw;
  height: 6vh;
}

.enter path {
  fill: #a6ffd4;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 11px;
}

.enter text {
  font-size: 62px;
  font-family: 'Switzer';
}

</style>
