import { createStore } from 'vuex'
import furnitures from '@/assets/json/furnitures.json'
import questions from '@/assets/json/questions.json'

export default createStore({
  state: {
    stories: null,
    storyOverlay: {
      left: {
        visibility: false,
        title: '',
        svg: ''
      },
      right: {
        visibility: false,
        title: '',
        svg: ''
      }
    },
    mapVisibility: false,
    infosVisibility: false,
    helpVisibility: true,
    referenceVisibility: false,
    storySubmissionFormVisibility: false,
    furnitures,
    questions,
    selectedTags: []
  },
  mutations: {
    setStories (state, stories) {
      state.stories = stories
    },
    setStoryOverlay (state, data) {
      const side: keyof typeof state.storyOverlay = data.side
      state.storyOverlay[side] = Object.assign(state.storyOverlay[side], data.content)
    },
    setMapVisibility (state, visibility) {
      state.mapVisibility = visibility
    },
    setInfosVisibility (state, visibility) {
      state.infosVisibility = visibility
    },
    setHelpVisibility (state, visibility) {
      state.helpVisibility = visibility
    },
    setReferenceVisibility (state, visibility) {
      state.referenceVisibility = visibility
    },
    setStorySubmissionFormVisibility (state, visibility) {
      state.storySubmissionFormVisibility = visibility
    },
    setSelectedTags (state, tags) {
      state.selectedTags = tags
    }
  },
  actions: {
    async getStories (store) {
      if (store.state.stories == null) {
        const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=story')
        const data = await response.json()
        store.commit('setStories', data)
      }
      return store.state.stories
    },
    setStoryOverlay (store, data) {
      store.commit('setStoryOverlay', data)
    },
    showInfo (store) {
      store.commit('setInfosVisibility', true)
      store.commit('setHelpVisibility', false)
    },
    showMap (store) {
      store.commit('setMapVisibility', true)
      store.commit('setHelpVisibility', false)
    },
    showHelp (store) {
      store.commit('setMapVisibility', false)
      store.commit('setInfosVisibility', false)
      store.commit('setHelpVisibility', true)
    },
    hideHelp (store) {
      store.commit('setHelpVisibility', false)
    },
    hideReference (store) {
      store.commit('setReferenceVisibility', false)
      store.commit('setHelpVisibility', true)
    },
    showReference (store) {
      store.commit('setReferenceVisibility', true)
      store.commit('setHelpVisibility', false)
    },
    setReferenceVisibility (store, visibility) {
      store.commit('setReferenceVisibility', visibility)
    },
    setStorySubmissionFormVisibility (store, visibility) {
      store.commit('setStorySubmissionFormVisibility', visibility)
    },
    setMapVisibility (store, visibility) {
      store.commit('setMapVisibility', visibility)
    },
    setInfosVisibility (store, visibility) {
      store.commit('setInfosVisibility', visibility)
    },
    setHelpVisibility (store, visibility) {
      store.commit('setHelpVisibility', visibility)
    },
    setSelectedTags (store, tags) {
      store.commit('setSelectedTags', tags)
    }
  },
  getters: {
    mapVisibility: state => {
      return state.mapVisibility
    },
    infosVisibility: state => {
      return state.infosVisibility
    },
    helpVisibility: state => {
      return state.helpVisibility
    },
    referenceVisibility: state => {
      return state.referenceVisibility
    },
    furnitures: state => {
      return state.furnitures
    },
    storyOverlay: state => (side: keyof typeof state.storyOverlay) => {
      return state.storyOverlay[side]
    },
    storySubmissionFormVisibility: state => {
      return state.storySubmissionFormVisibility
    },
    getQuestions: state => {
      return state.questions
    },
    getSelectedTags: state => {
      return state.selectedTags
    }
  }
})
