<template>
  <div class="infos" :class="{active: infosVisibility}">
    <header>
      <h2 class="flatShadow">About</h2>
      <img @click="close" src="@/assets/close-icon.svg" alt="Close Info Overlay" />
    </header>
    <content>
      <object :data="require(`@/assets/svg/about.svg`)" type="image/svg+xml">
        <img src="@/assets/svg/about.svg" alt="About" />
      </object>
    </content>
  </div>
</template>

<script>
export default {
  name: 'Infos',
  methods: {
    close () {
      this.$store.dispatch('showHelp')
    }
  },
  computed: {
    infosVisibility: {
      get () {
        return this.$store.getters.infosVisibility
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

* {
  --blueRGB: 100 144 221;
}

.infos {
  position: absolute;
  width: 95vw;
  height: 95vh;
  margin: 2vh 2vw;
  background-color: rgb(var(--blueRGB) / 95%);
  border: 0.2vw solid #000;
  top: 100vh;
  transition: top 1s ease-in 0s;
  display: grid;
  grid-template-rows: 5vw auto;
  z-index: 100;
}

.infos.active {
  top: 0vh;
  transition: top 1s ease-out 0s;
}

header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-bottom: 0.2vw solid #000;
  background-color: rgb(var(--blueRGB));
}

header > img {
  margin: 1vh 1vw;
}

header > img:hover {
  cursor: pointer;
}

content {
  overflow: hidden;
  display: grid;
  padding: 7vh 3vw;
}

content > section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 4vw;
  padding: 4vh 4vw 2vw;
  overflow-y: auto;
  font-family: 'Courier Prime';
}

h2 {
  border: 0.4vh solid #000;
  padding: 0.3vh 8vw;
  margin: 1vh 0vw 1.5vh;
  background-color: rgba(var(--blueRGB));
  font-size: 5.5vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

p, b, a {
  font-size: 1.8vh;
  text-align: left;
  line-height: 1.25;
  text-decoration: none;
  color: #000;
}

.bottom {
  text-align: left;
  margin-top: auto;
}

</style>
