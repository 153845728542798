import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'The Jungle of Eden'
    }
  },
  {
    path: '/dataCenter',
    name: 'DataCenter',
    component: () => import(/* webpackChunkName: "datacenter" */ '../views/DataCenter.vue'),
    meta: {
      title: 'DataCenter | The Jungle of Eden'
    }
  },
  {
    path: '/clearing',
    name: 'Clearing',
    component: () => import(/* webpackChunkName: "datacenter" */ '../views/Clearing.vue'),
    meta: {
      title: 'Clearing | The Jungle of Eden'
    }
  },
  {
    path: '/chatBot',
    name: 'ChatBot',
    component: () => import(/* webpackChunkName: "datacenter" */ '../views/ChatBot.vue'),
    meta: {
      title: 'ChatBot | The Jungle of Eden'
    }
  },
  {
    path: '/furniture-game',
    name: 'FurnishGame',
    component: () => import(/* webpackChunkName: "furniture-game" */ '../views/FurnitureGame.vue'),
    meta: {
      title: 'Furniture Game | The Jungle of Eden'
    }
  },
  {
    path: '/stories',
    name: 'Stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/Stories.vue'),
    meta: {
      title: 'Stories | The Jungle of Eden'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
