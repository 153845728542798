<template>
  <div class="audio">
    <audio loop>
      <source src="@/assets/jungle_sound.mp3" type="audio/mpeg">
      <source src="@/assets/jungle_sound.ogg" type="audio/ogg">
    </audio>
  </div>
</template>

<script>
export default {
  name: 'Audio'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.audio {
  position: absolute;
  top: 2vh;
  left: 24vw;
}
</style>
