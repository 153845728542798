<template>
  <div class="help" :class="{active: helpVisibility}">
    <!-- <svg @click="showMap" class="map flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.28 250.44">
      <path d="M158.18,8.29C113.31-1.44,74.47,16.54,51.72,27.06,17.13,43.07,11,57.16,9.25,61.6-4.59,97.49,23.53,147.45,34.37,166.71,49,192.66,57.24,208.66,73.85,217c32.79,16.49,50.86-18.89,91.51-6.76,30.51,9.1,31.86,32.46,56.82,34.54,30.29,2.51,66.85-28.68,74.17-65.32C309.22,115,233.27,24.58,158.18,8.29Z"/>
      <text text-anchor="middle" x="150" y="150">Map</text>
    </svg> -->

    <svg @click="showInfos" class="info flatShadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.32 186.62">
      <path d="M118,6.12C62.71,10.08,2.49,54.59,5.62,91.36c1.62,19,23.74,34,68,63.93C99,172.48,112,181.08,126.24,181.12c31.76.11,61.79-29,74-63.93,2.26-6.47,16.77-47.91-1.65-80.07C178.38,1.8,134.06,5,118,6.12Z"/>
      <text text-anchor="middle" x="110" y="135">?</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Infos',
  methods: {
    showMap () {
      console.log('show map')
    },
    showInfos () {
      console.log('show infos')
      this.$store.dispatch('showInfo')
    }
  },
  computed: {
    helpVisibility: {
      get () {
        return this.$store.getters.helpVisibility
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.help {
  position: fixed;
  display: flex;
  right: 2vw;
  top: -8vh;
  width: 23vh;
  height: 7vh;
  justify-content: flex-end;
  transition: top 0.5s ease-in 0s;
  z-index: 100;
}

.help.active {
  top: 2vh;
  transition: top 0.5s ease-out 0.5s;
}

svg {
  overflow: visible;
}

svg:hover {
  cursor: pointer;
}

svg.map path {
  fill: #64afa6;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 11px;
}

svg.map text {
  font-size: 5.5em;
}

svg.info path {
  fill: #a1e5e1;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 11px;
}

svg.info text {
  font-size: 125px;
}

</style>
