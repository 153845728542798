<template>
  <div class="home">
    <div id="title">
      <h1>
        <span id="the">The</span>
        <span id="jungle">Jungle</span>
        <span id="of">of</span>
        <span id="eden">Eden</span>
      </h1>
    </div>
    <div id="container">
      <ArrivalBoard :msg="lastVisit"/>
      <UserFactsForm ref="userFactsForm" />
    </div>
    <img class="leafs" src="@/assets/leaves-home-1x.png" srcset="@/assets/leaves-home-1x.png 2000w, @/assets/leaves-home-2x.png 3840w"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ArrivalBoard from '@/components/ArrivalBoard.vue'
import UserFactsForm from '@/components/UserFactsForm.vue'

export default {
  name: 'Home',
  components: {
    ArrivalBoard,
    UserFactsForm
  },
  data () {
    return {
      lastVisit: ''
    }
  },
  async created () {
    const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=lastUserOnline')
    const data = await response.json()
    this.lastVisit = `At ${new Date(data[0].updated).toLocaleTimeString()} someone entered the jungle`
  }
}
</script>

<style scoped>
  .home {
    width: calc(100vw - 2vh);
    height: 98vh;
    background-color: #004E63;
    border: 1vh solid #004E63;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url('../assets/leaves-home.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #title {
    padding-left: 2vw;
  }

  #title h1 {
    color: #fff5f3;
    margin: 0;
    margin-top: -5vh;
    font-size: 17vh;
    text-align: left;
    line-height: 0.7;
  }

  #container {
    display: grid;
    grid-template-rows: minmax(4vh, 21vh) auto;
    z-index: 10;
    padding: 5vh 7vw 5vh 0vw;
    justify-items: center;
  }

  span {
    display: block;
  }

  span::before {
      content: '\A';
      white-space: pre;
  }

  span#the {
    margin-left: 2vw;
  }

  span#jungle {
    margin-left: 3vw;
  }

  span#of {
    margin-left: 11vw;
  }

  span#eden {
    margin-left: 9vw;
  }

  .arrivalBoard {
    border: 0.4vh solid #FFB19C;
    color: #FCEE21;
    font-size: 15vh;
  }

  img.leafs {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    pointer-events: none;
  }
</style>
